import { MonetaryAmount } from "./MonetaryAmount";

export class RepaymentDto {
	public claimId = "";
	public customerId = "";
	public transactionAmount: MonetaryAmount = MonetaryAmount.euro(0);
	public eventType = "REPAYMENT_BOOKED";

	constructor(claimId: string, customerId: string, transactionsAmount: MonetaryAmount, eventType: string) {
		this.claimId = claimId;
		this.customerId = customerId;
		this.transactionAmount = transactionsAmount;
		this.eventType = eventType;
	}
}
