export class FormOptions {
	public testAccount?: string ;
	public invoiceType?: string ;
	public paymentStatus?: string ;
	public collectionStatus?: string ;
	public hasInstallments?: boolean;
	public insured?: boolean;
	public hasInsuranceCase?: boolean;
	public insuranceCaseStartDate?: string;
	public insuranceCaseEndDate?: string;
	public installmentScheduleUpdated?: boolean;
	public principleAmount?: number;
	public paidTotalAmount?: number;
	public outstandingTotalAmount?: number;
	public parkedAmount?: number;
	public overdueAmount?: number;
	public installmentCount?: number;
	public paidInstallmentCount?: number;
	public firstInstallmentDate?: string;
	public lastUnpaidDueDate?: string;
	public hasFees?: boolean;
	public principal?: number;
	public interest?: number;
	public dunningFees?: number;
	public insuranceFees?: number;
	public chargebackFees?: number;
	public defaultInterestFees?: number;
}

