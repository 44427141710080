import { REPAYMENT_TEST_DATA_ENDPOINT } from "../meta-env";

import type { ClaimInformationDto } from "../domain/ClaimInformationDto";
import { RepaymentDto } from "../domain/RepymentDto";
import { MonetaryAmount } from "../domain/MonetaryAmount";


export async function createRepaymentTestData(claimInformation: ClaimInformationDto, claimId: string, token: string|undefined) {

	const repayment = new RepaymentDto(
		claimId,
		claimInformation.customerId,
		new MonetaryAmount(15, "EUR"),
		claimInformation.eventType
	);


	const requestConfig = {
		method: "POST",
		headers: {
			"Authorization": `Bearer ${token}`,
			"Content-Type": "application/json"
		},
		body: JSON.stringify(repayment)
	};
	const requestUrl = `${REPAYMENT_TEST_DATA_ENDPOINT}`;
	const response = await fetch(requestUrl, requestConfig);
	console.log(response.json());

}
