import { ClaimAmount, ClaimAmounts, Fees } from "./ClaimAmounts";
import type { CollectionStatus } from './Collectionstatus'
import type { InsuranceCase } from './InsuranceCase'
import { MonetaryAmount } from "./MonetaryAmount";

export class ClaimInformationDto {
	public invoiceReceiptNumber = "invoiceReceiptNumber";
	public customerId = "";
	public paymentMethod = "DIRECT_DEBIT";
	public installmentsCount = 1;
	public paidInstallmentsCount: number | undefined = undefined;
	public insured: boolean | undefined = undefined;
	public installmentScheduleUpdated: boolean | undefined = undefined;
	public insuranceCase: InsuranceCase | undefined = undefined;
	public fullyPaid = "NO";
	public lastUnpaidDueDate: string | undefined;
	public firstInstallmentDate: string | undefined;
	public claimStatus = "ACTIVE";
	public collectionStatus: CollectionStatus| undefined;
	public eventType = "CLAIM_UPDATED";
	public claimAmounts: ClaimAmounts = new ClaimAmounts(
		new ClaimAmount(
			new MonetaryAmount(0.00, "EUR"),
			new MonetaryAmount(0.00, "EUR"),
			new MonetaryAmount(0.00, "EUR"),
			new Fees(
				new MonetaryAmount(0.00, "EUR"),
				new MonetaryAmount(0.00, "EUR"),
				new MonetaryAmount(0.00, "EUR"),
				new MonetaryAmount(0.00, "EUR"),
				new MonetaryAmount(0.00, "EUR"),
			)
		),
		new ClaimAmount(
			new MonetaryAmount(0.00, "EUR"),
			new MonetaryAmount(0.00, "EUR"),
			new MonetaryAmount(0.00, "EUR"),
			new Fees(
				new MonetaryAmount(0.00, "EUR"),
				new MonetaryAmount(0.00, "EUR"),
				new MonetaryAmount(0.00, "EUR"),
				new MonetaryAmount(0.00, "EUR"),
				new MonetaryAmount(0.00, "EUR"),
			)
		),
		new ClaimAmount(
			new MonetaryAmount(0.00, "EUR"),
			new MonetaryAmount(0.00, "EUR"),
			new MonetaryAmount(0.00, "EUR"),
			new Fees(
				new MonetaryAmount(0.00, "EUR"),
				new MonetaryAmount(0.00, "EUR"),
				new MonetaryAmount(0.00, "EUR"),
				new MonetaryAmount(0.00, "EUR"),
				new MonetaryAmount(0.00, "EUR"),
			)
		),
		new MonetaryAmount(0.00, "EUR"),
		new MonetaryAmount(0.00, "EUR")
	);

}
