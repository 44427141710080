import { PurchaseIntentDto } from '../domain/PurchaseIntentDto'
import InesTestData from '../ines38TestData.json'
import type { FormOptions } from '../domain/FormOptions'

export function updatePurchaseIntents(selectedFormOptions: FormOptions) {
    const purchaseIntent = new PurchaseIntentDto()

    purchaseIntent.customerId = getCustomerId(selectedFormOptions.testAccount)
    purchaseIntent.purchaseIntentAmounts.actual.total.amount = selectedFormOptions.principleAmount !== undefined ?
        selectedFormOptions.principleAmount : 0
    purchaseIntent.purchaseIntentAmounts.outstanding.total.amount = selectedFormOptions.outstandingTotalAmount !== undefined ?
        selectedFormOptions.outstandingTotalAmount : 0
    purchaseIntent.purchaseIntentAmounts.paid.total.amount = selectedFormOptions.paidTotalAmount !== undefined ?
        selectedFormOptions.paidTotalAmount : purchaseIntent.purchaseIntentAmounts.actual.total.amount

    purchaseIntent.purchaseIntentAmounts.parked.amount = selectedFormOptions.parkedAmount !== undefined ?
        selectedFormOptions.parkedAmount : 0

    return purchaseIntent
}

function getCustomerId(testAccount: string | undefined) {
    switch (testAccount) {
        case InesTestData.accountName:
            return InesTestData.customerId
        default:
            return ''
    }
}
