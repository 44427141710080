import type { MonetaryAmount } from "./MonetaryAmount";

export class ClaimAmounts {
	constructor(
		public actual: ClaimAmount,
		public outstanding: ClaimAmount,
		public paid: ClaimAmount,
		public overdue: MonetaryAmount,
		public parked: MonetaryAmount
	) {
	}
}

export class ClaimAmount {
	constructor(
		public total: MonetaryAmount,
		public principal: MonetaryAmount,
		public interest: MonetaryAmount,
		public fees: Fees
	) {
	}
}

export class Fees {
	constructor(
		public totalFees: MonetaryAmount,
		public dunningFees: MonetaryAmount,
		public insuranceFees: MonetaryAmount,
		public chargebackFees: MonetaryAmount,
		public defaultInterestFees: MonetaryAmount,
	) {
	}
}
