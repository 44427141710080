
export enum CollectionStatus {
	WAIVED = "WAIVED",
	SENT_FOR_COLLECTION = "SENT_FOR_COLLECTION",
	REMOVED_FROM_COLLECTION = "REMOVED_FROM_COLLECTION",
	PAYMENT_PLAN_TERMINATED = "PAYMENT_PLAN_TERMINATED",
}




