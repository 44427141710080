import { MonetaryAmount } from './MonetaryAmount'
import { PurchaseIntentAmountsDetail } from './PurchaseIntentsAmounts'
import { PurchaseIntentsAmounts } from './PurchaseIntentsAmounts'

export class PurchaseIntentDto {
    public customerId = ''
    public purchaseIntentAmounts: PurchaseIntentsAmounts = new PurchaseIntentsAmounts(
        new PurchaseIntentAmountsDetail(new MonetaryAmount(0.00, 'EUR')),
        new PurchaseIntentAmountsDetail(new MonetaryAmount(0.00, 'EUR')),
        new PurchaseIntentAmountsDetail(new MonetaryAmount(0.00, 'EUR')),
        new MonetaryAmount(0.00, 'EUR'),
    )
}
