import { ClaimInformationDto } from '../domain/ClaimInformationDto'
import { CollectionStatus } from '../domain/Collectionstatus'
import { InsuranceCase } from '../domain/InsuranceCase'
import { CollectionStatusOptions } from '../FormHelper'
import { PaymentStatusOptions } from '../FormHelper'
import type { FormOptions } from '../domain/FormOptions'
import Ines38TestData from '../ines38TestData.json'

export function updateClaimInformation(selectedFormOptions: FormOptions) {
    const claimInformation: ClaimInformationDto = new ClaimInformationDto()

    claimInformation.customerId = getCustomerId(selectedFormOptions.testAccount)
    claimInformation.fullyPaid = getFullyPaid(selectedFormOptions.paymentStatus)
    claimInformation.eventType = getEventType(selectedFormOptions.paymentStatus)
    claimInformation.paymentMethod = getPaymentMethod(selectedFormOptions.invoiceType)
    claimInformation.claimAmounts.actual.total.amount = getActualTotalAmount(selectedFormOptions)
    claimInformation.claimAmounts.outstanding.total.amount = getOutstandingTotalAmount(selectedFormOptions)
    claimInformation.claimAmounts.paid.total.amount = getPaidTotalAmount(selectedFormOptions)
    claimInformation.claimAmounts.overdue.amount = getOverdueAmount(selectedFormOptions)
    claimInformation.claimAmounts.parked.amount = getParkedAmount(selectedFormOptions)
    claimInformation.claimAmounts.actual.principal.amount = getPrinciple(selectedFormOptions)
    claimInformation.claimAmounts.actual.interest.amount = getInterest(selectedFormOptions)
    claimInformation.claimAmounts.actual.fees.dunningFees.amount = getDunningFees(selectedFormOptions)
    claimInformation.claimAmounts.actual.fees.chargebackFees.amount = getChargebackFees(selectedFormOptions)
    claimInformation.claimAmounts.actual.fees.insuranceFees.amount = getInsuranceFees(selectedFormOptions)
    claimInformation.claimAmounts.actual.fees.defaultInterestFees.amount = getDefaultInterestFees(selectedFormOptions)
    claimInformation.claimAmounts.actual.fees.totalFees.amount = getFeesTotalAmount(selectedFormOptions)
    claimInformation.lastUnpaidDueDate = selectedFormOptions.lastUnpaidDueDate
    claimInformation.installmentsCount = getInstallmentCount(selectedFormOptions)
    claimInformation.paidInstallmentsCount = getPaidInstallmentCount(selectedFormOptions)
    claimInformation.insured = selectedFormOptions.insured
    claimInformation.installmentScheduleUpdated = selectedFormOptions.installmentScheduleUpdated
    claimInformation.insuranceCase = getInsuranceCase(selectedFormOptions)
    claimInformation.firstInstallmentDate = getFirstInstallmentDate(selectedFormOptions)
    claimInformation.claimStatus = getClaimStatus(selectedFormOptions)
    claimInformation.collectionStatus = getCollectionStatus(selectedFormOptions)

    return claimInformation
}

function getCustomerId(testAccount?: string) {
    switch (testAccount) {
        case Ines38TestData.accountName:
            return Ines38TestData.customerId
        default:
            return ''
    }
}

function getEventType(paymentStatus?: string) {
    switch (paymentStatus) {
        case PaymentStatusOptions.NOT_PAID:
        case PaymentStatusOptions.OVERDUE:
            return 'CLAIM_UPDATED'
        case PaymentStatusOptions.PAID:
        case PaymentStatusOptions.COLLECTION:
            return 'CLAIM_CLOSED'
        default:
            return ''
    }
}

function getPaymentMethod(invoiceType?: string) {
    for (const invoice of Ines38TestData.invoices) {
        if (invoice.invoiceType === invoiceType) {
            return invoice.paymentMethod
        }
    }
    return ''
}

function getFullyPaid(paymentStatus?: string) {
    switch (paymentStatus) {
        case PaymentStatusOptions.NOT_PAID:
        case PaymentStatusOptions.OVERDUE:
            return 'NO'
        case PaymentStatusOptions.PAID:
        case PaymentStatusOptions.COLLECTION:
            return 'YES'
        default:
            return ''
    }
}

function getActualTotalAmount(selectedFormOptions: FormOptions) {
    return getPrinciple(selectedFormOptions) + getFeesTotalAmount(selectedFormOptions)
}

function getPaidTotalAmount(selectedFormOptions: FormOptions) {
    if (selectedFormOptions.paymentStatus === PaymentStatusOptions.PAID) {
        return getActualTotalAmount(selectedFormOptions)
    }
    return 0
}

function getOutstandingTotalAmount(
    selectedFormOptions: FormOptions,
) {

    let outstandingTotalAmount = 0

    if (selectedFormOptions.paymentStatus === PaymentStatusOptions.NOT_PAID
        || selectedFormOptions.paymentStatus == PaymentStatusOptions.OVERDUE
        || selectedFormOptions.paymentStatus == PaymentStatusOptions.COLLECTION) {
        outstandingTotalAmount = getActualTotalAmount(selectedFormOptions)

        // if with installments
        if (selectedFormOptions.principleAmount !== undefined
            && selectedFormOptions.installmentCount !== undefined
            && selectedFormOptions.paidInstallmentCount != undefined) {
            const outstandingInstallmentsCount = selectedFormOptions.installmentCount - selectedFormOptions.paidInstallmentCount
            outstandingTotalAmount =
                (outstandingTotalAmount / selectedFormOptions.installmentCount) * outstandingInstallmentsCount

        }
        return outstandingTotalAmount
    }
    return 0
}

function getOverdueAmount(selectedFormOptions: FormOptions) {
    return selectedFormOptions.overdueAmount !== undefined && selectedFormOptions.overdueAmount !== null ?
        selectedFormOptions.overdueAmount : 0
}

function getParkedAmount(selectedFormOptions: FormOptions) {
    return selectedFormOptions.parkedAmount !== undefined && selectedFormOptions.parkedAmount !== null ?
        selectedFormOptions.parkedAmount : 0
}

function getInstallmentCount(selectedFormOptions: FormOptions) {
    return selectedFormOptions.installmentCount !== undefined && selectedFormOptions.installmentCount !== null ?
        selectedFormOptions.installmentCount : 1
}

function getPaidInstallmentCount(selectedFormOptions: FormOptions) {
    return selectedFormOptions.paidInstallmentCount !== undefined && selectedFormOptions.paidInstallmentCount !== null ?
        selectedFormOptions.paidInstallmentCount : undefined
}

function getFirstInstallmentDate(selectedFormOptions: FormOptions) {
    return selectedFormOptions.firstInstallmentDate
}

function getPrinciple(selectedFormOptions: FormOptions) {
    return selectedFormOptions.principleAmount !== undefined && selectedFormOptions.principleAmount !== null ?
        selectedFormOptions.principleAmount : 0
}

function getInterest(selectedFormOptions: FormOptions) {
    return selectedFormOptions.interest !== undefined && selectedFormOptions.interest !== null ?
        selectedFormOptions.interest : 0
}

function getDunningFees(selectedFormOptions: FormOptions) {
    return selectedFormOptions.dunningFees !== undefined && selectedFormOptions.dunningFees !== null ?
        selectedFormOptions.dunningFees : 0
}

function getChargebackFees(selectedFormOptions: FormOptions) {
    return selectedFormOptions.chargebackFees !== undefined && selectedFormOptions.chargebackFees !== null ?
        selectedFormOptions.chargebackFees : 0
}

function getInsuranceFees(selectedFormOptions: FormOptions) {
    return selectedFormOptions.insuranceFees !== undefined && selectedFormOptions.insuranceFees !== null ?
        selectedFormOptions.insuranceFees : 0
}

function getDefaultInterestFees(selectedFormOptions: FormOptions) {
    return selectedFormOptions.defaultInterestFees !== undefined && selectedFormOptions.defaultInterestFees !== null ?
        selectedFormOptions.defaultInterestFees : 0
}

function getFeesTotalAmount(selectedFormOptions: FormOptions) {
    return getInterest(selectedFormOptions)
        + getDunningFees(selectedFormOptions)
        + getChargebackFees((selectedFormOptions))
        + getInsuranceFees((selectedFormOptions))
        + getDefaultInterestFees(selectedFormOptions)
}

function getClaimStatus(selectedFormOptions: FormOptions) {
    return selectedFormOptions.paymentStatus === PaymentStatusOptions.PAID ||
    (selectedFormOptions.paymentStatus === PaymentStatusOptions.COLLECTION
        && selectedFormOptions.collectionStatus === CollectionStatusOptions.SENT_FOR_COLLECTION) ?
        'CLOSED' : 'ACTIVE'
}

function getCollectionStatus(selectedFormOptions: FormOptions) {
    if (selectedFormOptions.paymentStatus === PaymentStatusOptions.COLLECTION) {
        if (selectedFormOptions.collectionStatus === CollectionStatusOptions.SENT_FOR_COLLECTION) {
            return CollectionStatus.SENT_FOR_COLLECTION
        } else if (selectedFormOptions.collectionStatus === CollectionStatusOptions.REMOVED_FROM_COLLECTION) {
            return CollectionStatus.REMOVED_FROM_COLLECTION
        } else if (selectedFormOptions.collectionStatus === CollectionStatusOptions.PAYMENT_PLAN_TERMINATED) {
            return CollectionStatus.PAYMENT_PLAN_TERMINATED
        }
    }
    return undefined
}

function getInsuranceCase(selectedFormOptions: FormOptions) {
    return selectedFormOptions.insuranceCaseStartDate !== undefined && selectedFormOptions.insuranceCaseEndDate !== undefined ?
        new InsuranceCase(selectedFormOptions.insuranceCaseStartDate, selectedFormOptions.insuranceCaseEndDate) :
        undefined
}
