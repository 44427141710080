<script>
	export let label, value;
</script>

<div class="container-general-style">
	<div class="max-w-sm md:w-1/3">
		<label class="mx-1 text-gray-700 break-words">{label}</label>
	</div>
	<div class="max-w-sm md:w-2/3">
		<div
			 class="text-gray-400 font-medium">{value}</div>
	</div>
</div>
