import type { MonetaryAmount } from "./MonetaryAmount";

export class PurchaseIntentsAmounts {
	constructor(
		public actual: PurchaseIntentAmountsDetail,
		public outstanding: PurchaseIntentAmountsDetail,
		public paid: PurchaseIntentAmountsDetail,
		public parked: MonetaryAmount
	) {
	}
}

export class PurchaseIntentAmountsDetail {
	constructor(public total: MonetaryAmount) {
	}
}
