<script lang="ts" type="module">
    import { afterUpdate } from 'svelte'
    import DropMenu from '../components/DropMenu.svelte'
    import Button from '../components/Button.svelte'
    import { getOrderAuthorizationId } from '../logic/FormHelper.ts'
    import { getPositionIds } from '../logic/FormHelper.ts'
    import {
        getCollectionStatusOptions,
        showInstallmentInsuranceOptions, showInsuranceCaseOptions,
        showParkedOptions,
    } from '../logic/FormHelper.ts'
    import { PaymentStatusOptions, showInstallmentFeesOptions } from '../logic/FormHelper.ts'
    import { isPrepaymentInvoice } from '../logic/FormHelper.ts'
    import {
        getInvoiceId,
        getInvoiceTypeOptions,
        getPaymentStatusOptions,
        getTestAccountOptions,
        resetSelectedFormOptions,
        showFeesCheckbox,
        showFeesOptions,
        showInstallmentsCheckbox,
        showInstallmentsOptions,
        showOverdueOptions,
    } from '../logic/FormHelper.ts'
    import NumberInput from '../components/NumberInput.svelte'
    import { updatePurchaseIntents } from '../logic/factory/PurchaseIntentsFactory'
    import { DEVELOP_BDS_ENDPOINT } from '../logic/meta-env'
    import { DEVELOP_INVOICE_ENDPOINT } from '../logic/meta-env'
    import { createClaimTestData } from '../logic/service/ClaimInformationRestService.ts'
    import DateInput from '../components/DateInput.svelte'
    import Highlight from 'svelte-highlight'
    import json from 'svelte-highlight/languages/json'
    import DefaultThemeHighlight from 'svelte-highlight/styles/atom-one-light'
    import Checkbox from '../components/Checkbox.svelte'
    import { updateClaimInformation } from '../logic/factory/ClaimInformationFactory.ts'
    import { FormOptions } from '../logic/domain/FormOptions'
    import Info from '../components/Info.svelte'
    import { createPurchaseIntentTestData } from '../logic/service/PurchaseIntentRestService'

    let request
    let response
    let isResponseOk
    let responseAsJson = ''
    let disableButton = false
    const selectedFormOptions = new FormOptions()

    // construct drop menus
    const testAccountOptions = getTestAccountOptions()
    const collectionStatusOptions = getCollectionStatusOptions()
    $: invoiceTypeOptions = getInvoiceTypeOptions(selectedFormOptions.testAccount)
    $: paymentStatusOptions = getPaymentStatusOptions(selectedFormOptions.invoiceType)
    $: invoiceId = getInvoiceId(selectedFormOptions.invoiceType)
    $: positionIds = getPositionIds(selectedFormOptions.invoiceType)

    // reset selectedFormOptions when unselecting extra options
    $: resetSelectedFormOptions(selectedFormOptions)

    // update request object on user interaction
    afterUpdate(() => {
        if (isPrepaymentInvoice(selectedFormOptions)) {
            request = updatePurchaseIntents(selectedFormOptions)
        } else {
            request = updateClaimInformation(selectedFormOptions)
        }
    })

    // handle submit
    async function handleSubmit() {
        disableButton = true

        if (isPrepaymentInvoice(selectedFormOptions)) {
            response = createPurchaseIntentTestData(request, getOrderAuthorizationId())

        } else {
            response = createClaimTestData(request, invoiceId)
        }

        response.then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return Promise.reject(response)
            }
        })
            .then(response => {
                isResponseOk = true
                disableButton = false
                responseAsJson = response

                const invoiceUrl = `${DEVELOP_INVOICE_ENDPOINT}${invoiceId}`
                window.open(invoiceUrl)

                for(const positionId of positionIds) {
                    window.open(`${DEVELOP_BDS_ENDPOINT}${positionId}`)
                }
            })
            .catch(() => {
                isResponseOk = false
                responseAsJson = response.JSON
                disableButton = false
            })
    }

    // request and response objects as json
    $: requestJSON = JSON.stringify(request, null, 1)
    $: responseJSON = JSON.stringify(responseAsJson, null, 1)
</script>

<svelte:head>
  <title>Create Test Data</title>
  <meta name="description" content="Create Test Data" />
  {@html DefaultThemeHighlight}
</svelte:head>
<section>
  <div class="page-container m-4">
    <div class="space-y-6">
      <p class="font-semibold">
        Hier kannst du Testdaten über unsere Test-API erstellen. Logge dich zuerst auf <a
        class="text-blue-600 hover:underline"
        href="https://develop.otto.de" target="_blank"
      >develop.otto.de</a> unter
        <a
          class=" text-gray-600"
          href="mailto:Ines.ApiAchtunddreizig@otto.de"
        >Ines.ApiAchtunddreizig@otto.de</a>
        ein.
      </p>
      <div class="container-general-style">
        <!-- input section -->
        <form on:submit|preventDefault={handleSubmit} class="max-w-m md:w-1/2 mr-2">
          <div class="container space-y-4">
            <DropMenu
              bind:value={selectedFormOptions.testAccount} label="testAccount" options={testAccountOptions}
              placeHolder="select a test account first" required={true}
            />

            <DropMenu
              bind:value={selectedFormOptions.invoiceType} label="invoiceType" options={invoiceTypeOptions}
              placeHolder="select an invoice type" required={true}
            />

            {#if selectedFormOptions.invoiceType !== ""}
              <Info label="invoiceId" value={invoiceId} />
            {/if}

            {#if isPrepaymentInvoice(selectedFormOptions)}
              <NumberInput
                bind:value={selectedFormOptions.principleAmount} label="principleAmount"
                placeholder="e.g. 100.0" minValue="0" step="0.01" required={true}
              />

              <NumberInput
                bind:value={selectedFormOptions.paidTotalAmount} label="paidTotalAmount"
                placeholder="e.g. 80.0" minValue="0" step="0.01"
              />

              <NumberInput
                bind:value={selectedFormOptions.outstandingTotalAmount} label="outstandingTotalAmount"
                placeholder="e.g. 20.0" minValue="0" step="0.01"
              />

              <NumberInput
                bind:value={selectedFormOptions.parkedAmount} label="parkedAmount"
                placeholder="e.g. 120.0" minValue="0" step="0.01"
              />
            {:else}

              <DropMenu
                bind:value={selectedFormOptions.paymentStatus} label="paymentStatus" options={paymentStatusOptions}
                placeHolder="select a status" required={true}
              />

              {#if selectedFormOptions.paymentStatus === PaymentStatusOptions.COLLECTION}
                <DropMenu
                  bind:value={selectedFormOptions.collectionStatus}
                  label="collectionStatus"
                  options={collectionStatusOptions}
                  placeHolder="select a collection status"
                  required={true}
                />
              {/if}

              <NumberInput
                bind:value={selectedFormOptions.principleAmount} label="principleAmount"
                placeholder="e.g. 100.0" minValue="0" step="0.01" required={true}
              />

              {#if showOverdueOptions(selectedFormOptions)}
                <NumberInput
                  bind:value={selectedFormOptions.overdueAmount} label="overdueAmount"
                  placeholder="e.g. 50.0" minValue="0" required={true}
                />

                <DateInput bind:value={selectedFormOptions.lastUnpaidDueDate} label="lastUnpaidDueDate" />
              {/if}

              {#if showParkedOptions(selectedFormOptions)}
                <NumberInput
                  bind:value={selectedFormOptions.parkedAmount} label="parkedAmount"
                  placeholder="e.g. 50.0" minValue="0"
                />
              {/if}

              {#if showInstallmentsCheckbox(selectedFormOptions)}
                <Checkbox bind:value={selectedFormOptions.hasInstallments} label="hasInstallments" />
              {/if}

              {#if showInstallmentsOptions(selectedFormOptions)}
                <NumberInput
                  bind:value={selectedFormOptions.installmentCount} label="installmentCount" style="margin-right: 10px;"
                  placeholder="should be >= 1" minValue="1" required={true} isSubElement={true}
                />

                <NumberInput
                  bind:value={selectedFormOptions.paidInstallmentCount} label="paidInstallmentCount"
                  minValue="1" placeholder="should be <= Installments Count"
                  maxValue={selectedFormOptions.installmentCount -1}
                  isSubElement={true}
                />
                <DateInput
                  bind:value={selectedFormOptions.firstInstallmentDate}
                  label="firstInstallmentDate"
                  isSubElement={true}
                />
                <Checkbox bind:value={selectedFormOptions.insured} label="insured" isSubElement={true} />

                {#if showInstallmentInsuranceOptions(selectedFormOptions)}
                  <Checkbox
                    bind:value={selectedFormOptions.hasInsuranceCase}
                    label="hasInsuranceCase"
                    isSubElement={true}
                  />

                  {#if showInsuranceCaseOptions(selectedFormOptions)}
                    <DateInput
                      bind:value={selectedFormOptions.insuranceCaseStartDate}
                      label="insuranceCaseStartDate"
                      isSubElement={true}
                    />
                    <DateInput
                      bind:value={selectedFormOptions.insuranceCaseEndDate}
                      label="insuranceCaseEndDate"
                      isSubElement={true}
                    />
                  {/if}
                {/if}

                <Checkbox
                  bind:value={selectedFormOptions.installmentScheduleUpdated}
                  label="installmentScheduleUpdated"
                  isSubElement={true}
                />
              {/if}

              {#if showFeesCheckbox(selectedFormOptions)}
                <Checkbox bind:value={selectedFormOptions.hasFees} label="hasFees" />
              {/if}

              {#if showFeesOptions(selectedFormOptions)}
                {#if showInstallmentFeesOptions(selectedFormOptions)}
                  <NumberInput
                    bind:value={selectedFormOptions.interest} label="interest"
                    placeholder="e.g. 2.68" minValue="0" step="0.01"
                    isSubElement={true}
                  />
                  <NumberInput
                    bind:value={selectedFormOptions.insuranceFees} label="insuranceFees"
                    placeholder="e.g. 0.98" minValue="0" step="0.01"
                    isSubElement={true}
                  />
                {/if}
                <NumberInput
                  bind:value={selectedFormOptions.dunningFees} label="dunningFees"
                  placeholder="e.g. 3.00" minValue="0" step="0.01"
                  isSubElement={true}
                />
                <NumberInput
                  bind:value={selectedFormOptions.defaultInterestFees} label="defaultInterestFees"
                  placeholder="e.g. 4.32" minValue="0" step="0.01"
                  isSubElement={true}
                />
                <NumberInput
                  bind:value={selectedFormOptions.chargebackFees} label="chargebackFees"
                  placeholder="e.g. 10.00" minValue="0" step="0.01"
                  isSubElement={true}
                />
              {/if}
            {/if}
            <Button label="Create Testdata" disableButtun={disableButton} />

            {#if isResponseOk === true}
              <div class="font-bold text-green-700">request successful!</div>
            {:else if isResponseOk === false}
              <div class="font-bold text-red-700">request denied! click again</div>
            {/if}
          </div>
        </form>
        <!-- json output section -->
        <div class="max-w-m md:w-3/4">
          <div class="md:inline-flex w-11/12 h-[35rem]">
            <Highlight class="json-output" language={json} code={"Request " + requestJSON} />
            <Highlight class="json-output" language={json} code={"Response " + responseJSON} />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
