<script>
	export let label, value, isSubElement;
	function toggleCheckbox() {value = !value;}
</script>

<div class="container-general-style">
	<div class="max-w-sm md:w-1/3">
		<label class="mx-1 text-gray-700 break-words {isSubElement ? 'ml-7' : ''} {value ? 'font-bold' : ''}">{label}</label>
	</div>
	<div class="max-w-sm md:w-2/3">
		<div class="flex items-center mb-4">
			<input type="checkbox" checked={value} class="checkbox" on:click={toggleCheckbox}>
		</div>
	</div>
</div>
