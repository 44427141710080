<script>
	import { createEventDispatcher } from "svelte";

	export let label, value, options, placeHolder, required, isSubElement;
	const dispatch = createEventDispatcher();

	function handleChange(event) {
		const newValue = event.target.value;
		value = newValue;
		dispatch("change", newValue);
	}
</script>

<div class="container-general-style">
	<div class="max-w-sm md:w-1/3">
		<label for="drop-menu" class="text-gray-700 mx-1 break-words {isSubElement ? 'ml-7' : ''}">{label}</label>
		{#if required === true}
			<span class="text-red-500 required-dot">*</span>
		{/if}
	</div>
	<div class="max-w-sm md:w-2/3">
		<select
			id="drop-menu"
			class="drop-menu"
			on:change={handleChange}
			bind:value
			required={required}
		>
			<option value="" disabled selected>{placeHolder}</option>
			{#each options as option (option.value)}
				<option>{option.value}</option>
			{/each}
		</select>
	</div>
</div>
