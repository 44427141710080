<script>
	export let label, value, isSubElement;
	let defaultValue = new Date();
	defaultValue.setDate(defaultValue.getDate() - 1);
	value ??= defaultValue.toISOString().split("T")[0];
</script>

<div class="container-general-style">
	<div class="max-w-sm md:w-1/3">
		<label for="input-text" class="mx-1 text-gray-700 break-words {isSubElement ? 'ml-7' : ''}">{label}</label>
	</div>
	<div class="max-w-sm md:w-2/3">
		<input type="date"
					 id="input-text"
					 class="input-text"
					 bind:value={value}
		/>
	</div>
</div>
