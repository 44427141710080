import type { ClaimInformationDto } from '../domain/ClaimInformationDto'
import { getToken } from '../utils/InitializeKeycloak'
import { createRepaymentTestData } from './RepaymentRestService'
import { getClaimId } from '../FormHelper'
import { Claim_Information_TEST_DATA_ENDPOINT } from '../meta-env'

export async function createClaimTestData(claimInformation: ClaimInformationDto, invoiceId: string) {
    const token = await getToken()
    const requestConfig = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(claimInformation),
    }
    const claimId = getClaimId(invoiceId)
    const requestUrl = `${Claim_Information_TEST_DATA_ENDPOINT}${claimId}`
    const response = await fetch(requestUrl, requestConfig)

    if (!response.ok) {
        throw new Error('Could not create testdata: ' + response.statusText)
    }

    // fetch transactions
    if (claimInformation.eventType == 'REPAYMENT_BOOKED') {
        await createRepaymentTestData(claimInformation, claimId, token)
    }

    return response
}
