import type { FormOptions } from './domain/FormOptions'
import Ines38TestData from './ines38TestData.json'

export const PaymentStatusOptions = {
    NOT_PAID: 'not paid',
    OVERDUE: 'overdue',
    PAID: 'paid',
    COLLECTION: 'collection',
}

export const CollectionStatusOptions = {
    SENT_FOR_COLLECTION: 'SENT_FOR_COLLECTION',
    REMOVED_FROM_COLLECTION: 'REMOVED_FROM_COLLECTION',
    PAYMENT_PLAN_TERMINATED: 'PAYMENT_PLAN_TERMINATED',
}

export function getTestAccountOptions() {
    return [
        { value: Ines38TestData.accountName },
    ]
}

export function getInvoiceTypeOptions(selectedTestAccount?: string) {
    if (selectedTestAccount === Ines38TestData.accountName) {
        return Ines38TestData.invoices.map(invoice => ({ value: invoice.invoiceType }));
    }
    return [];
}

export function getInvoiceId(selectedInvoiceType?: string) {
    for (const invoice of Ines38TestData.invoices) {
        if (invoice.invoiceType === selectedInvoiceType) {
            return invoice.invoiceId
        }
    }
    return 'no invoice id existed for selected invoice type'
}
export function getPositionIds(selectedInvoiceType?: string) {
    for (const invoice of Ines38TestData.invoices) {
        if (invoice.invoiceType === selectedInvoiceType) {
            return invoice.positionId
        }
    }
    return 'no position id existed for selected invoice type'
}


export function getPaymentStatusOptions(selectedInvoiceType?: string) {
    if (isDirectDebitInvoice(selectedInvoiceType)) {
        return [
            { value: PaymentStatusOptions.NOT_PAID },
            { value: PaymentStatusOptions.PAID },
            { value: PaymentStatusOptions.COLLECTION },
        ]
    } else {
        return Object.values(PaymentStatusOptions).map(value => ({ value }));
    }
}

function isDirectDebitInvoice(selectedInvoiceType?: string) {
    for (const invoice of Ines38TestData.invoices) {
        if (invoice.invoiceType === selectedInvoiceType) {
            if (invoice.paymentMethod === 'DIRECT_DEBIT') {
                return true
            }
        }
    }
    return false
}

export function getCollectionStatusOptions() {
    return Object.values(CollectionStatusOptions).map(value => ({ value }));
}

export function getClaimId(invoiceId: string) {
    for (const invoice of Ines38TestData.invoices) {
        if (invoice.invoiceId === invoiceId) {
            return invoice.claimId
        }
    }
    return 'no claim id existed for selected invoice id'
}

export function getOrderAuthorizationId() {
    for (const invoice of Ines38TestData.invoices) {
        if (invoice.paymentMethod === "PREPAYMENT") {
            return invoice.orderAuthorizationId
        }
    }
    return ''
}

export function isPrepaymentInvoice(selectedFormOptions: FormOptions) {
    return selectedFormOptions.invoiceType === "Vorkasse";
}

export function showOverdueOptions(selectedFormOptions: FormOptions) {
    return selectedFormOptions.paymentStatus === PaymentStatusOptions.OVERDUE
}

export function showParkedOptions(selectedFormOptions: FormOptions) {
    return selectedFormOptions.paymentStatus === PaymentStatusOptions.NOT_PAID
}

export function showInstallmentsCheckbox(selectedFormOptions: FormOptions) {
    return (selectedFormOptions.paymentStatus === PaymentStatusOptions.NOT_PAID
            || selectedFormOptions.paymentStatus === PaymentStatusOptions.OVERDUE)
        && isInvoiceWithInstallment(selectedFormOptions)
}

function isInvoiceWithInstallment(selectedFormOptions: FormOptions) {
    for (const invoice of Ines38TestData.invoices) {
        if (invoice.invoiceType === selectedFormOptions.invoiceType) {
            if (invoice.hasInstallments === true) {
                return true
            }
        }
    }
    return false
}

export function showInstallmentsOptions(selectedFormOptions: FormOptions) {
    return selectedFormOptions.hasInstallments === true && showInstallmentsCheckbox(selectedFormOptions)
}

export function showFeesCheckbox(selectedFormOptions: FormOptions) {
    return selectedFormOptions.paymentStatus === PaymentStatusOptions.PAID
        || selectedFormOptions.paymentStatus === PaymentStatusOptions.NOT_PAID
        || selectedFormOptions.paymentStatus === PaymentStatusOptions.OVERDUE
}

export function showFeesOptions(selectedFormOptions: FormOptions) {
    return selectedFormOptions.hasFees === true && showFeesCheckbox(selectedFormOptions)
}

export function showInstallmentFeesOptions(selectedFormOptions: FormOptions) {
    return showInstallmentsOptions(selectedFormOptions) || selectedFormOptions.paymentStatus === PaymentStatusOptions.PAID
}

export function showInstallmentInsuranceOptions(selectedFormOptions: FormOptions) {
    return selectedFormOptions.insured
}

export function showInsuranceCaseOptions(selectedFormOptions: FormOptions) {
    return selectedFormOptions.hasInsuranceCase
}

export function resetSelectedFormOptions(selectedFormOptions: FormOptions) {
    if (!showOverdueOptions(selectedFormOptions)) {
        selectedFormOptions.overdueAmount = undefined
        selectedFormOptions.lastUnpaidDueDate = undefined
    }
    if (!showParkedOptions(selectedFormOptions)) {
        selectedFormOptions.parkedAmount = undefined
    }
    if (!showInstallmentsOptions(selectedFormOptions)) {
        selectedFormOptions.installmentCount = undefined
        selectedFormOptions.paidInstallmentCount = undefined
        selectedFormOptions.firstInstallmentDate = undefined
        selectedFormOptions.insured = undefined
        selectedFormOptions.installmentScheduleUpdated = undefined
    }

    if (!showInstallmentsOptions(selectedFormOptions) && selectedFormOptions.paymentStatus !== PaymentStatusOptions.PAID) {
        selectedFormOptions.interest = undefined
        selectedFormOptions.insuranceFees = undefined
        selectedFormOptions.installmentScheduleUpdated = undefined
    }

    if (!showFeesOptions(selectedFormOptions)) {
        selectedFormOptions.interest = undefined
        selectedFormOptions.dunningFees = undefined
        selectedFormOptions.chargebackFees = undefined
        selectedFormOptions.insuranceFees = undefined
        selectedFormOptions.defaultInterestFees = undefined
    }

    if (!showInstallmentInsuranceOptions(selectedFormOptions)) {
        selectedFormOptions.hasInsuranceCase = undefined
        selectedFormOptions.insuranceCaseStartDate = undefined
        selectedFormOptions.insuranceCaseEndDate = undefined
    }

    if (!showInsuranceCaseOptions(selectedFormOptions)) {
        selectedFormOptions.insuranceCaseStartDate = undefined
        selectedFormOptions.insuranceCaseEndDate = undefined
    }
}
