<script>
	export let label, value, step, required, minValue, maxValue, placeholder, isSubElement;
</script>

<div class="container-general-style">
	<div class="max-w-sm md:w-1/3">
		<label for="input-text" class="mx-1 text-gray-700 break-words {isSubElement ? 'ml-7' : ''}">{label}</label>
		{#if required === true}
			<span class="text-red-500 required-dot">*</span>
		{/if}
	</div>
	<div class="max-w-sm md:w-2/3">
		<input type="number"
					 id="input-text"
					 class="input-text"
					 step={step}
					 placeholder={placeholder}
					 bind:value={value}
					 min={minValue}
					 max={maxValue}
					 required={required}
		/>
	</div>
</div>
