import { DEVELOP_INVOICE_ENDPOINT } from '../meta-env'
import { PREPAYMENT_TEST_DATA_ENDPOINT } from '../meta-env'
import type { PurchaseIntentDto } from '../domain/PurchaseIntentDto'
import { getToken } from '../utils/InitializeKeycloak'

export async function createPurchaseIntentTestData(
    purchaseIntent: PurchaseIntentDto,
    orderAuthorizationId: string,
) {
    const token = await getToken()
    const requestConfig = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(purchaseIntent),
    }
    const requestUrl = `${PREPAYMENT_TEST_DATA_ENDPOINT}${orderAuthorizationId}`
    const response = await fetch(requestUrl, requestConfig)

    if (!response.ok) {
        throw new Error('Could not create testdata: ' + response.statusText)
    }

    return response
}

